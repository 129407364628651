import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoIdToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useLocation } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { AuthContext } from './auth-context';
import { GenericObject } from '../../utils/GenericObject';
// import { GlobalDeps } from '../../GlobalDeps';
// import { SessionStorageKeys } from '../../config/storage-config';

export const AuthContextProvider = ({
  children,
}: PropsWithChildren<string | unknown>) => {
  const { search } = useLocation();

  const [userTokenData, setUserTokenData] = useState<GenericObject | undefined>(
    undefined
  );

  const [error, setError] = useState<string | undefined>(undefined);
  const [triedToFetchTheUser, setTriedToFetchTheUser] =
    useState<boolean>(false);
  const queryString = search.replace('?', '').split('=');
  const isAuthenticatedThroughOkta =
    queryString &&
    queryString.length > 1 &&
    queryString[0] === 'authenticatedThrough' &&
    queryString[1] === 'Microsoft';

  const login = useCallback(() => {
    // if (!isAuthenticatedThroughOkta) {
    //   GlobalDeps.storageService.setItem(
    //     SessionStorageKeys.INITIAL_PATH_NAME_BEFORE_AUTH,
    //     `${pathname}${search}`,
    //     false
    //   );
    // }

    Auth.federatedSignIn({ customProvider: 'Microsoft' }).catch(
      (error: string) => {
        setError(error);
      }
    );
  }, []);

  const logout = useCallback(() => {
    Auth.signOut().catch((error: string) => {
      setError(error);
    });
    sessionStorage.clear();
  }, []);

  const logoutBeforeTokenExpires = useCallback(
    (jwtExp: number) => {
      const exp = jwtExp * 1000;
      const currentDate = new Date().getTime();
      const before30Seconds = 30000;

      setTimeout(() => {
        logout();
      }, exp - currentDate - before30Seconds);
    },
    [logout]
  );

  const getUserData = useCallback(async (): Promise<
    GenericObject | undefined
  > => {
    try {
      const currentSession: CognitoUserSession = await Auth.currentSession();
      const userData: CognitoIdToken = currentSession.getIdToken();

      logoutBeforeTokenExpires(userData.payload.exp);

      return userData.payload;
    } catch {
      return undefined;
    }
  }, [logoutBeforeTokenExpires]);

  const onLoad = useCallback(async () => {
    try {
      Hub.listen('auth', async (data: any) => {
        if (
          data.payload.event === 'configured' ||
          data.payload.event === 'signIn'
        ) {
          setUserTokenData(await getUserData());
        }
      });

      Auth.configure(appConfig.auth);

      if (isAuthenticatedThroughOkta) {
        await login();
      }

      Hub.remove('auth', () => {
        return null;
      });
    } catch (error: any) {
      setError(error);
    } finally {
      setTriedToFetchTheUser(true);
    }
  }, [getUserData, isAuthenticatedThroughOkta, login]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AuthContext.Provider
      value={{ userTokenData, triedToFetchTheUser, error, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
