/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Grid, List } from '@rentacenter/racstrap';
import { UserComponent } from '../../UserInfo/userComponent';
import { HelperComponent } from '../../Helper/helperComponent';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { headerStyles } from '../../../headerStyles';
import { GlobalSearch } from './GlobalSearch';
import RACLogo from './RACLogo';
import Menu from './Menu';
import StoreNumberWidget from './StoreNumberWidget';
import FieldDevice from './FieldDevice';
import { fieldDeviceStyles } from '../../../fieldDeviceSty';
import { configCall } from '../../../api/user';
// import { responseStructure } from './MakeResponseStructure';

// const props.allowedRoutes = responseStructure()
const searchCustomerPath = 'searchcustomer';
const DISPLAYSEARCHBAR = 'DISPLAYSEARCHBAR';

function DynamicHeaderComponent(props: any) {
  const location = useLocation();
  // eslint-disable-next-line no-console
  console.log(`props inside DynamicHeaderComponent`, props, location);
  const classes = headerStyles();
  const fieldDeviceClasses: any = fieldDeviceStyles();
  const [disPlaySearchBar, setDisPlaySearchBar] = useState('');
  const [priorityOverride, setPriorityOverride] = useState<boolean>(false);
  const [activeAppName, setActiveAppName] = useState('');
  const path = window.location.pathname.split('/');
  const searchIconClick = (disPlaySearchBar: string) => {
    setDisPlaySearchBar(disPlaySearchBar);
  };

  const routeOverride = (menuElement: any): boolean => {
    const foundOveride = false;

    if (
      priorityOverride &&
      menuElement &&
      menuElement?.app === 'Account Management'
    ) {
      menuElement?.appComponents?.forEach((comp: any) => {
        if (comp && comp?.appSubComponents) {
          comp.appSubComponents = comp.appSubComponents.filter(
            (sub: any) => sub.path != '/am/priorityvisit'
          );
        }
      });
    }

    return foundOveride;
  };

  async function runWithErrorSuppression<T>(
    fn: () => Promise<T>
  ): Promise<T | null> {
    // Save the existing handler
    const previousHandler = window.onunhandledrejection;

    // Set a temporary handler to prevent UI impact
    window.onunhandledrejection = (event: any) => {
      console.warn(
        'Temporarily suppressing unhandled rejection:',
        event?.reason
      );
      event.preventDefault(); // Prevent UI impact
    };
    try {
      return await fn(); // Execute the function
    } catch (error) {
      console.error('Caught an error inside runWithErrorSuppression:', error);
      return null; // Prevent UI impact
    } finally {
      // Restore the original handler
      window.onunhandledrejection = previousHandler;
    }
  }

  const menuOverride = async (activeStore: any): Promise<any> => {
    if (activeStore) {
      const store = window.sessionStorage.getItem('storeNumber');
      // check to see if it is different
      setPriorityOverride(false);
      const request = {
        storeNumbers: [store],
        paramKeyNames: ['EnablePriorityFieldVisit'],
      };
      try {
        const configResult = await configCall(request);
        if (configResult) {
          const priorityFVoverride =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'EnablePriorityFieldVisit'
            );
          if (priorityFVoverride?.[0]?.paramValue === '0') {
            // only if it is there and set to zero it is overwritten
            setPriorityOverride(true);
          }
        }
      } catch (eee: any) {
        console.log('purposely absorbing config override');
      }
    }
  };

  let initialPath = '';
  useEffect(() => {
    let appName = '';
    if (props.allowedRoutes?.length > 0 && props.allowedRoutes[0].path) {
      appName = props.allowedRoutes[0].app;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialPath = props.allowedRoutes[0].path;
    }
    if (
      props.allowedRoutes?.length > 0 &&
      props?.allowedRoutes[0]?.appComponents?.length > 0 &&
      props?.allowedRoutes[0]?.appComponents[0]?.appSubComponents?.length > 0
    ) {
      appName =
        props?.allowedRoutes[0]?.appComponents[0]?.appSubComponents[0]?.appName;
      initialPath =
        props?.allowedRoutes[0]?.appComponents[0]?.appSubComponents[0]?.path;
    }
    console.log('inital path and appName', initialPath, appName);
    setActiveAppName(appName);
    // history.push({ pathname: initialPath });
  }, []);

  useEffect(() => {
    const appNameInPath = location.pathname?.split('/')[1];
    const appDetail = props.allowedRouteUrls.find(
      (el: any) => el.routePath.replaceAll('/', '') == appNameInPath
    );
    console.log('dynamic header app name', appDetail, appNameInPath);
    setActiveAppName(appDetail?.appName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // when the active store changes get the override
    setPriorityOverride(false);
    runWithErrorSuppression(() => menuOverride(props.activeStore));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeStore]);

  return (
    <div className="App">
      <Grid
        classes={{ root: clsx(classes.racNavbarExpand, classes.racFixedTop) }}
      >
        <Grid className={classes.racContainerFluid}>
          <RACLogo initialPath={initialPath} />
          <Grid
            classes={{
              root: clsx(classes.racNavbarCollapse, classes.ContentEnd),
            }}
          >
            <List
              className={`${classes.racNavbarNav} ${fieldDeviceClasses.dMdNone}`}
            >
              {props.allowedRoutes.map((userNavElements: any, index: any) => {
                if (!routeOverride(userNavElements)) {
                  return (
                    <Menu
                      key={index}
                      index={index}
                      userNavElements={userNavElements}
                      activeAppName={activeAppName}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </List>
            <Grid className={classes.dFlex}>
              {path[3] !== searchCustomerPath ? (
                <Link
                  to="#"
                  className={clsx({
                    [classes.racGlobalSearchBtnShow]:
                      disPlaySearchBar == DISPLAYSEARCHBAR,
                    [classes.racGlobalSearchBtn]: disPlaySearchBar == '',
                    [classes.cursorDefault]:
                      path[2] == 'customer' && path[3] == searchCustomerPath,
                  })}
                  onClick={() => searchIconClick(DISPLAYSEARCHBAR)}
                ></Link>
              ) : null}
              <GlobalSearch
                disPlaySearchBar={disPlaySearchBar}
                searchOutsideClick={(val: string) => searchIconClick(val)}
              />
              <StoreNumberWidget
                dropdown={props.dropDownData}
                initialPath={initialPath}
              />
              <HelperComponent throwDropdownValue={props.dropDownData} />
              <UserComponent userData={props?.userData} />
              <FieldDevice navPermission={props.allowedRoutes} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DynamicHeaderComponent;
